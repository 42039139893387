<template>
  <div class="HistoricalRecord" style="background: #fff" v-loading="loading">
    <div v-if="data.length <= 0" style="padding-top: 20vh">
      <el-empty description="暂无数据"></el-empty>
    </div>
    <div
      v-else
      class="list"
      v-infinite-scroll="loadData"
      infinite-scroll-distance="1"
    >
      <div
        v-for="(item, index) in data"
        :key="index"
        class="listItem"
        @click="toDetail(item.Id)"
      >
        <div class="flex">
          <span class="label">货单号:</span>
          <b>{{ item.BillId }}</b>
          <span>
            <el-tag v-if="item.Status == 0">待签收</el-tag>
            <el-tag v-if="item.Status == 1" type="success">已签收</el-tag>
            <el-tag v-if="item.Status == 2" type="danger">已拒收</el-tag>
            <el-tag v-if="item.Status == 3" type="warning">有差异</el-tag>
            <el-tag v-if="item.Status == 4" type="danger">已作废</el-tag>
          </span>
        </div>
        <div class="flex">
          <span class="label">销售方:</span>
          <b>{{ item.Title }}</b>
        </div>
        <div class="flex">
          <span class="label">收货方:</span>
          <b>{{ item.cCusName }}</b>
        </div>
        <div class="flex">
          <span class="label">发货日期:</span>
          <b>{{ item.CreateTime?.substring(0, 19) }}</b>
        </div>
        <div class="flex">
          <span class="label">收货日期:</span>
          <b>{{ item.SigningDate? item.SigningDate.substring(0, 19):"" || "N/A" }}</b>
        </div>
      </div>
      <div v-show="loading" class="tips">加载中...</div>
      <div v-show="data.length >= pagination.total" class="tips">
        没有更多了
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      loading: false,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
      },
      openid: "",
    };
  },
  async mounted() {
    document.title = "我的回执";
    let openid = sessionStorage.getItem("openid");
    if (openid) {
      this.openid = openid;
      this.getData();
      return
    }
    let query = location.href.split("?");
    if (query.length > 1) {
      console.log(query);
      let code = query[1].split("&")[0].split("=")[1];
      console.log(code);
      // 获取access_token
      let result = await this.$http.get(
        "/Base_Manage/Home/GetWxAccessToken?code=" + code
      );
      console.log(JSON.parse(result.Data));
      let { openid } = { ...JSON.parse(result.Data) };
      this.openid = openid;
      sessionStorage.setItem("openid", openid);
      this.getData();
    } else {
      this.$message.error("信息获取失败");
    }
  },
  methods: {
    getData() {
      this.$http
        .post("/BO/BillList/GetOpenBillst", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: {
            Keyword: this.openid,
          },
        })
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.Success) {
            this.data = res.Data;
            this.pagination.total = res.Total;
          }
        });
    },
    loadData(e) {
      if (this.data.length >= this.pagination.total) return;
      this.pagination.current++;
      this.loading = true;
      this.$http
        .post("/BO/BillList/GetOpenBillst", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: {
            Keyword: this.openid,
          },
        })
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.Success) {
            this.data = this.data.concat(res.Data);
            this.pagination.total = res.Total;
          }
        });
    },
    toDetail(Id) {
      this.$router.push("/SendSMSMobile?id=" + Id + "&qrcode=1");
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}
.HistoricalRecord {
  padding: 10px;
  padding-top: 0px;
  background: #4eaaff;
  height: 100%;
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  .list {
    font-size: 14px;
    // background: #fff;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    height: 100vh;
    overflow-y: auto;
    .listItem {
      margin: 10px 0;
      line-height: 2;
      border-radius: 5px;
      box-shadow: 0 1px 3px 1px #ccc;
      box-sizing: border-box;
      padding-left: 10px;
      background: #00000003;
      overflow: hidden;
      .label {
        width: 70px;
        color: #000;
      }
      b {
        flex: 1;
      }
    }
    .tips {
      text-align: center;
      color: #000;
    }
  }
}
</style>